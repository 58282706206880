<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4 text-left">
                <h2 class="card-title">{{ $t("buttons.BtnStatsDetails").toUpperCase() }}</h2>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3">
                    <base-input>
                      <el-select class="select-primary"
                          placeholder="PÉRIODE"
                          v-model="PeriodeSelects.Period">
                          <el-option 
                          v-for="option in PeriodeSelects.ListPeriod"
                          class="select-primary"
                          :value="option.value"
                          :label="option.label.toUpperCase()"
                          :key="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-md-3">
                    <base-input :error="getError('date de début')">
                      <el-date-picker 
                        v-model="StartDate" 
                        type="date" 
                        value-format="yyyy/MM/dd" 
                        placeholder="DATE DE DÉBUT"
                        :picker-options="pickerOptions"
                        name="date de début"
                        v-validate="'required'"
                      ></el-date-picker>
                    </base-input>
                  </div>
                  <div class="col-md-3">
                    <base-input>
                      <el-date-picker 
                        v-model="EndDate" 
                        type="date" 
                        value-format="yyyy/MM/dd" 
                        placeholder="DATE DE FIN"
                        :picker-options="pickerOptions"
                      ></el-date-picker>
                    </base-input>
                  </div>
                  <base-input class="col-md-3" v-if="id_user == '299'">
                    <el-select filterable clearable class="select-primary"
                      clearable
                      placeholder="PRODUIT"
                      v-model="product"
                      >
                      <el-option 
                        v-for="option in list_product"
                        class="select-primary"
                        :value="option"
                        :label="option.toUpperCase()"
                        :key="option">
                      </el-option>
                    </el-select>
                  </base-input>
                  <base-input class="col-md-3">
                    <el-select filterable multiple collapse-tags class="select-primary"
                      clearable
                      placeholder="CAMPAGNES"
                      v-model="Campagne"
                      >
                      <el-option 
                        v-for="option in liste_campagnes"
                        class="select-primary"
                        :value="option.id_programme"
                        :label="option.Titre.toUpperCase()"
                        :key="option.id_programme">
                      </el-option>
                    </el-select>
                  </base-input>
                  <base-input class="col-md-3" placeholder="SUBID" v-model="Subid" type="number">
                  </base-input>
                  <div class="col-md-9 float-right">
                    <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                      <base-button v-if="$apollo.queries.statsGlobales_Editeur.loading" 
                        loading 
                        class="btn btn-round btn-just-icon btn-default mt-0 d-block"
                        >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                      <base-button 
                        v-else 
                        class="btn btn-round btn-just-icon btn-primary" 
                        @click="send"
                        >{{ $t("buttons.BtnSearch").toUpperCase() }}</base-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12" v-if='hideChart'>
                <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                  <label
                    style="font-size: 0.75rem"
                    v-for="(option, index) in bigLineChartCategories"
                    :key="option.name"
                    class="btn btn-sm btn-info btn-simple"
                    :class="{ active: ChartStatGlobal.activeIndex === index }"
                    :id="index"
                  >
                    <input
                      type="radio"
                      @click="initBigChart(index)"
                      name="options"
                      autocomplete="off"
                      :checked="ChartStatGlobal.activeIndex === index"
                    />
                    <span class="d-none d-sm-block">{{ option.name }}</span>
                    <span class="d-block d-sm-none">
                      <el-tooltip
                        :content="option.name"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                        <i :class="option.icon"></i>
                      </el-tooltip>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div>
            <line-chart
              v-if='hideChart'
              :labels="ChartStatGlobal.labels"
              :chart-data="ChartStatGlobal.chartData"
              :extra-options="ChartStatGlobal.extraOptions"
              :height="200"
            >
            </line-chart>
            <!-- <center> 
              <base-button v-if='!hideChart' loading type="primary">Chargement...</base-button>
            </center> -->
          </div>
          <div class="col-sm-12" v-if="hideChartDetail">
            <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
              <label
                style="font-size: 0.75rem"
                class="btn btn-sm btn-info btn-simple"
                :class="{ active: hideChartCA }"
              >
                <input
                  type="radio"
                  @click="hideChartCA=true;hideChartLead=false"
                  name="options"
                  autocomplete="off"
                  :checked="hideChartCA"
                />
                <span class="d-none d-sm-block">{{ $t('Pages.dashboard.Expenses').toUpperCase() }}</span>
                <span class="d-block d-sm-none">
                  <el-tooltip
                    :content="$t('Pages.dashboard.Expenses').toUpperCase()"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  >
                    <i class="tim-icons icon-coins"></i>
                  </el-tooltip>
                </span>
              </label>
              <label
                style="font-size: 0.75rem"
                class="btn btn-sm btn-info btn-simple"
                :class="{ active: hideChartLead }"
              >
                <input
                  type="radio"
                  @click="hideChartCA=false;hideChartLead=true"
                  name="options"
                  autocomplete="off"
                  :checked="hideChartLead"
                />
                <span class="d-none d-sm-block">LEADS</span>
                <span class="d-block d-sm-none">
                  <el-tooltip
                    content="LEADS"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  >
                    <i class="tim-icons icon-badge"></i>
                  </el-tooltip>
                </span>
              </label>
            </div>
          </div>
          <div>
            <line-chart
              v-if='hideChartCA'
              :labels="ChartStatGlobal.labels"
              :chart-data="lineChart2.chartData"
              :extra-options="lineChart2.extraOptions"
              :height="200"
            >
            </line-chart>
          </div>
          <div>
            <line-chart
              v-if="hideChartLead"
              :labels="ChartStatGlobal.labels"
              :chart-data="lineChartLead.chartData"
              :extra-options="lineChartLead.extraOptions"
              :height="200"
            >
            </line-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-12 text-left">
              </div>
            </div>
          </div>
          <div class="p-2" data="black">
            <el-table :data="AllstatsGlobales" show-summary :summary-method="getSummaries">
              <el-table-column
                label="Jour"
                prop="jour"
                min-width="235"
                show-overflow-tooltip
              >
                <template scope="scope">
                  <!-- <label style="cursor: pointer;" @click="Details(scope.row)"> -->
                    {{ scope.row.jour.toUpperCase() }}
                  <!-- </label> -->
                </template>
              </el-table-column>
              <el-table-column
                label="Impressions"
                prop="impressions"
                min-width="150"
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.impressions) }}
                </template>
              </el-table-column>
              <el-table-column
                label="Clics"
                prop="clics"
                min-width="100"
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.clics) }}
                </template>
              </el-table-column>
              <el-table-column
                label="Leads"
                prop="leads"
                min-width="100"
                sortable
              >
                <template scope="scope">
                  <!-- <label style="cursor: pointer;" @click="Details(scope.row)">
                    <u> -->
                    {{ formatThounsends(scope.row.leads) }}
                    <!-- </u>
                  </label> -->
                </template>
              </el-table-column>
              <el-table-column
                label="Ventes"
                prop="ventes"
                min-width="105"
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.ventes) + ' €' }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('Pages.dashboard.Expenses').toUpperCase()"
                prop="ca"
                min-width="120"
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.ca, true, 0) + ' €' }}
                </template>
              </el-table-column>
              <el-table-column
                label="CTR"
                prop="CTR"
                min-width="100"
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.CTR, true, 2) + ' %' }}
                </template>
              </el-table-column>
              <el-table-column
                label="ECPC"
                prop="ECPC"
                min-width="100"
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.ECPC, true, 2) + ' €' }}
                </template>
              </el-table-column>
            </el-table>
            <center>
              <base-button v-if='$apollo.queries.statsGlobales_Editeur.loading' loading type="primary"></base-button>
            </center>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option, DatePicker } from "element-ui";
import { Table, TableColumn } from 'element-ui';
import LineChart from 'src/components/Charts/LineChart'
import config from '@/config';
import * as chartConfigs from '@/components/Charts/config';
import gql from 'graphql-tag'
import moment from 'moment';

let CurentDate = new Date()
let limitdate = new Date(2020, 0, 1)

let bigChartDatasetOptions = {
  fill: true,
  borderColor: config.colors.info,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: config.colors.info,
  pointBorderColor: 'rgba(255,255,255,0)',
  pointHoverBackgroundColor: '#2380f7',
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4,
}

let bigChartLabels = ['LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM', 'DIM']

let bigChartData = [
  [],
  []
]

export default {
  apollo: {
    liste_campagnes: {
      query: gql`
        query ($id_client: Int) {
          list_programme (id_client: $id_client, etat: "valide", Titre: "", idcpart: 0, orderBy:[ { field: "Titre", order: ASC } ]) {
            id_programme
            Titre
          }
        }
      `,
      fetchPolicy: 'network-only',
      variables() {
        return {
          id_client: this.id_client
        }
      },
      update: data => data.list_programme
    },
    list_programme_detail: {
      query: gql`query {
        list_programme_detail(flag:"details"){
          id_programme
          Titre
          repertoire
          provenance
          idcpart
        }
      }`,
      update: data => data.list_programme_detail
    },
    statsGlobales_Editeur: {
      query : gql`query ($day: Int,$month: String!,$year: String!,$id_client: Int,$dateEnd: String, $bases: [Int], $campagnes: [Int], $subid: Int) {
        statsGlobales_Editeur (
          day: $day
          month: $month
          year: $year
          id_client: $id_client
          droit: "an"
          dateEnd: $dateEnd
          bases: $bases
          campagnes: $campagnes
          subid: $subid
        )
        {
          flag
          returnDay
          jour
          impressions
          clics
          leads
          ventes
          ECPC
          CTR
          ca
          Campagnes {
            Titre
            id_campagne
            id_programme
            leads
            ca
            jour
          }
        }        
      }`,
      fetchPolicy: 'network-only',
      skip(){
        return this.SkipQuery 
      },
      variables() {
        return {
          day: null,
          month: (new Date().getUTCMonth() + 1) + "",
          year: new Date().getUTCFullYear() + "",
          id_client: this.id_client,
          dateEnd: this.EndDate,
          bases: null,
          campagnes: null,
          subid: null
        }
      },
      result({ data, loader, networkStatus }) {
        this.returnDay = data.statsGlobales_Editeur[0].returnDay
        this.Flag = data.statsGlobales_Editeur[0].flag
        this.AllstatsGlobales = [...this.AllstatsGlobales,...data.statsGlobales_Editeur]
        this.DateStart.add(5, 'days');   
      },
    }
  },
  computed: {
    bigLineChartCategories() {
      return [
        { name: this.$t('Pages.dashboard.Expenses').toUpperCase(), icon: 'tim-icons icon-coins' }, 
        { name: 'LEADS', icon: 'tim-icons icon-badge' }
      ];
    }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    LineChart,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker
  },
  data() {
    return {
      id_user : JSON.parse(localStorage.getItem('utilisateur')).id,
      DateStart: null,
      product: "",
      list_product: ["SANTE", "ANIMAUX", "MRH", "AUTO", "EMPRUNTEUR"],
      Campagne: [],
      Campagne_withDuplicate: [],
      mounth: [ 
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Aout",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre"
      ],
      Subid: null,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() || time.getTime() < limitdate.getTime();
        }
      },
      PeriodeSelects: {
        Period: '',
        ListPeriod: [
          { value: "YESTERDAY" , label: "Hier"},
          { value: "CURRENTDAY" , label: "Aujourd'hui"},	
          { value: "CURRENTMONTH" , label: "Mois en cours"},
          { value: "LASTMONTH" , label: "Mois précédent"}
        ]
      },
      EndDate: null,
      StartDate: null,
      AllstatsGlobales: [],
      id_client: JSON.parse(localStorage.getItem('utilisateur')).id_clien_myaffil,
      returnDay: null,
      SkipQuery: true,
      Flag: false,
      hideChart: false,
      switchCa_Lead: true,
      hideChartLead: false,
      hideChartCA: false,
      hideChartDetail: false,
      SUMimpressions: 0,
      SUMclics: 0,
      SUMlead: 0,
      SUMventes: 0,
      SUMECPC: 0,
      SUMca: 0,
      ChartStatGlobal: {
        activeIndex: 0,
        chartData: {
          labels: bigChartLabels,
          datasets: [
            {
              ...bigChartDatasetOptions,
              data: bigChartData[0]
            }
          ],
        },
        extraOptions: {
          ...chartConfigs.lineChartOptionsBlue,
          tooltips: {
            callbacks: {
              label: function(tooltipItems, data) {
                if(data.datasets[0].data == bigChartData[0])
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " €"
                else
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " leads"
              }
            }
          },
          scales: {
            yAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.0)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  suggestedMax: 150,
                  suggestedMin: 0,
                  padding: 20,
                  fontColor: '#9e9e9e',
                  callback: function (value){
                    return value.toLocaleString()
                  }
                }
              }
            ],
            xAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.1)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  padding: 20,
                  fontColor: '#9e9e9e'
                }
              }
            ]
          }
        }
      },
      lineChart2: {
        chartData: {
          labels: [],
          datasets: []
        },
        extraOptions: {
          ...chartConfigs.lineChartOptionsBlue,
          tooltips: {
            callbacks: {
              label: function(tooltipItems, data) {
                return Math.floor(tooltipItems.yLabel).toLocaleString() + " €"
              }
            }
          },
          scales: {
            yAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.0)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  suggestedMax: 50,
                  suggestedMin: 0,
                  padding: 20,
                  fontColor: '#9e9e9e',
                  callback: function (value){
                    return value.toLocaleString()
                  }
                }
              }
            ],
            xAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.1)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  padding: 20,
                  fontColor: '#9e9e9e'
                }
              }
            ]
          },
          legend: {
            display: true
          },
        }
      },
      lineChartLead: {
        chartData: {
          labels: [],
          datasets: []
        },
        extraOptions: {
          ...chartConfigs.lineChartOptionsBlue,
          tooltips: {
            callbacks: {
              label: function(tooltipItems, data) {
                return Math.floor(tooltipItems.yLabel).toLocaleString() + " leads"
              }
            }
          },
          scales: {
            yAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.0)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  suggestedMax: 50,
                  suggestedMin: 0,
                  padding: 20,
                  fontColor: '#9e9e9e',
                  callback: function (value){
                    return value.toLocaleString()
                  }
                }
              }
            ],
            xAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.1)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  padding: 20,
                  fontColor: '#9e9e9e'
                }
              }
            ]
          },
          legend: {
            display: true
          },
        }
      },
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    formatThounsends: function(value, fixe = false, number = 0){
      value != null ? value = value : value = 0
      if(fixe){
        value = value.toFixed(number)
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    initBigChart(index) {
      let chartData = {
        datasets: [{
          ...bigChartDatasetOptions,
          data: bigChartData[index]
        }],
        labels: bigChartLabels
      };
      // this.$refs.bigChart.updateGradients(chartData);
      this.ChartStatGlobal.chartData = chartData;
      this.ChartStatGlobal.activeIndex = index;
    },
    async send() {
      let validate
      await this.$validator.validateAll().then(isValid => {
        validate = isValid
      })
      if (!validate)
        return

      if (this.EndDate && this.StartDate) {
        var Difference_In_Time = new Date(this.EndDate).getTime() - new Date(this.StartDate).getTime();
        if (Difference_In_Time / (1000 * 3600 * 24) < 0) {
          return
        }

      }

      if (this.EndDate && limitdate.getTime() > new Date(this.EndDate).getTime()) {
        return
      }

      if (this.StartDate && limitdate.getTime() > new Date(this.StartDate).getTime()) {
        return
      }

      let array = []
      this.Campagne_withDuplicate = this.Campagne
      this.Campagne.forEach(element => {
        array = [...array,...this.list_programme_detail.filter(x => x.idcpart === parseInt(element))]
      });
      this.Campagne_withDuplicate = [...this.Campagne_withDuplicate,...array.map(a => a.id_programme)];
      this.hideChart = false
      this.hideChartDetail = false
      this.hideChartCA = false
      this.hideChartLead = false
      this.AllstatsGlobales = []

      this.DateStart = moment(this.StartDate)
      this.returnDay = parseInt(this.DateStart.format('D'))
      this.SkipQuery = false
      this.Flag = false
      while(!this.Flag){
        await this.$apollo.queries.statsGlobales_Editeur.refetch({
          day: this.returnDay,
          month: this.DateStart.format('M'),
          year: this.DateStart.format('YYYY'),
          id_client: this.id_client,
          droit: 'an',
          dateEnd: this.EndDate,
          bases: null,
          campagnes: this.Campagne_withDuplicate,
          subid: this.Subid
        })
      }
      let label = []
      let datalead = []
      let dataCA = []
      let SUMimpressions = 0
      let SUMclics = 0
      let SUMlead = 0
      let SUMventes = 0
      let SUMECPC = 0
      let SUMca = 0
      let CampagneArray = []
      for (let index = 0; index < this.AllstatsGlobales.length; index++) {
        SUMimpressions += this.AllstatsGlobales[index].impressions
        SUMlead += this.AllstatsGlobales[index].leads
        SUMventes += this.AllstatsGlobales[index].ventes
        SUMECPC += this.AllstatsGlobales[index].ECPC
        SUMca += this.AllstatsGlobales[index].ca
        SUMclics += this.AllstatsGlobales[index].clics

        //  EMPTY chart data
        this.lineChart2.chartData.datasets = []
        this.lineChartLead.chartData.datasets = []
        
        if(this.Campagne.length >= 2) {
          CampagneArray = CampagneArray.concat(this.AllstatsGlobales[index].Campagnes);
        }
        datalead.push(this.AllstatsGlobales[index].leads)
        dataCA.push(this.AllstatsGlobales[index].ca)
        label.push(this.AllstatsGlobales[index].jour.split(" ")[1] + "/" + this.AllstatsGlobales[index].jour.split(" ")[2] )
      }
      this.SUMimpressions = SUMimpressions
      this.SUMclics = SUMclics
      this.SUMlead = SUMlead
      this.SUMventes = SUMventes
      this.SUMECPC = SUMECPC
      this.SUMca = SUMca
      
      // ppush data in CHART CA & LEADS
      if (CampagneArray.length) {
        let distinct = alasql('SELECT distinct Titre, jour, ca, leads from ?',[CampagneArray])
        let grouped = alasql('SELECT Titre, jour, SUM([ca]) AS [ca], SUM([leads]) AS [leads] FROM ? GROUP BY Titre, jour',[distinct])
        for (let i = 0; i < this.Campagne.length; i++) {
          let CAarray = grouped.filter(x => x.Titre === this.liste_campagnes.find(p => p.id_programme === this.Campagne[i]).Titre).map(x => x.ca)
          let LEADarray = grouped.filter(x => x.Titre === this.liste_campagnes.find(p => p.id_programme === this.Campagne[i]).Titre).map(x => x.leads)
          
          let color = this.getRandomColor()

          // Push in chart CA
          this.lineChart2.chartData.datasets.push(
            {
              label: this.liste_campagnes.find(p => p.id_programme === this.Campagne[i]).Titre.toUpperCase(),
              fill: true,
              borderColor: color,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: color,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 2,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: CAarray
            },
          ) 
          // Push in chart Leads 
          this.lineChartLead.chartData.datasets.push(
            {
              label: this.liste_campagnes.find(p => p.id_programme === this.Campagne[i]).Titre.toUpperCase(),
              fill: true,
              borderColor: color,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: color,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 2,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: LEADarray
            },
          ) 

        }
      }
      bigChartData[0] = dataCA 
      bigChartData[1] = datalead
      bigChartLabels = label
      this.lineChart2.chartData.labels = label
      this.lineChartLead.chartData.labels = label
      this.initBigChart(0)
      if (this.Campagne.length >= 2) {
        this.hideChartDetail = true
        this.hideChartCA = true
        this.hideChart = false
        this.hideChartLead = false
      }
      else {
        this.hideChart = true
        this.hideChartDetail = false
        this.hideChartCA = false
        this.hideChartLead = false
      }
      this.SkipQuery = true
    },
    Details(row) {
      // let Details = {}
      // Details.jour = row.jour.split(' ')[3] + '/' + (this.mounth.indexOf(row.jour.split(' ')[2]) + 1) + '/' + row.jour.split(' ')[1]
      // Details.droit = 'af'
      // Details.id_client = JSON.parse(localStorage.getItem('utilisateur')).id_clien_myaffil
      // Details.id_programme = null
      // Details.programmes = this.Campagne_withDuplicate
      // Details.bases = this.Support
      // Details.title_programme = null
      // Details.manager = null
      // localStorage.setItem("Details", JSON.stringify(Details))
      // let routeData = this.$router.resolve({name: 'stats Detail Leads day for Editeur'});
      // window.open(routeData.href, '_blank');   
    },
    getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '';
          return;
        }
        else if( index === 1) {
          sums[index] = this.formatThounsends(this.SUMimpressions)
          return;
        }
        else if( index === 2) {
          sums[index] = this.formatThounsends(this.SUMclics)
          return;
        }
        else if( index === 3) {
          sums[index] = this.formatThounsends(this.SUMlead)
          return;
        }
        else if( index === 4) {
          sums[index] = this.formatThounsends(this.SUMventes) + " €"
          return;
        }
        else if( index === 7) {
          sums[index] = this.formatThounsends(this.SUMECPC, true, 2) + ' €'
          return;
        }
        else if( index === 6) {
          if(this.SUMclics != 0)
            sums[index] = this.formatThounsends((this.SUMlead/this.SUMclics)*100, true, 2) + ' %'
          else
            sums[index] = 0 + ' %'
          return;
        }
        else if( index === 5) {
          sums[index] = this.formatThounsends(this.SUMca, true, 0) + ' €'
          return;
        }
        else {
          sums[index] = '';
          return;
        }
      });
      return sums;
    },
  },
  watch: {
    'PeriodeSelects.Period'(value){
      if(value == "YESTERDAY") {
        this.StartDate = CurentDate.getFullYear() +"/"+ (CurentDate.getMonth()+1) +"/"+ (CurentDate.getDate()-1)
        this.EndDate = ""
      } else if(value == "CURRENTDAY") {
        this.StartDate = CurentDate.getFullYear() +"/"+ (CurentDate.getMonth()+1) +"/"+ CurentDate.getDate()
        this.EndDate = ""
      } else if(value == "CURRENTMONTH") {
        this.StartDate = CurentDate.getFullYear() +"/"+ (CurentDate.getMonth()+1) +"/"+ 1
        this.EndDate   = CurentDate.getFullYear() +"/"+ (CurentDate.getMonth()+1) +"/"+ CurentDate.getDate()
      } else if(value == "LASTMONTH") {
        this.StartDate = CurentDate.getFullYear() +"/"+ CurentDate.getMonth() +"/"+ 1
        this.EndDate   = CurentDate.getFullYear() +"/"+ CurentDate.getMonth() +"/"+ new Date(CurentDate.getFullYear(), CurentDate.getMonth() , 0).getDate()
      }
    },
    product(value) {
      let product = null
      if (value == "SANTE") {
        product = [
          '1 MUTUELLE',
          '1 MUTUELLE CPC',
          'ALINE ASSURANCES FB',
          'ALINE ASSURANCES FB CPC',
          'ARBORIA Sante',
          'ARBORIA Sante CPC',
          'ARBORIA Sante SENIOR',
          'DFS',
          'DFS CPC',
          'EASY MUTUELLE',
          'EASY MUTUELLE CPC',
          'LM ASSURANCES',
          'LM ASSURANCES CPC',
          'MATEVA ASSURANCE',
          'MATEVA ASSURANCE CPC',
          'MATEVA ASSURANCE SENIOR',
          'MELIA MUTUELLE',
          'MELIA MUTUELLE CPC',
          'MON GUSTAVE',
          'MON GUSTAVE CPC',
          'MON GUSTAVE Sante',
          'MON GUSTAVE Sante MB CPC',
          'MUTUELLE INTEGRALE',
          'MUTUELLE INTEGRALE CPC',
          'MUTUELLE INTEGRALE SENIOR',
          'MUTUELLE INTEGRALE SENIOR CPC',
          'MUTUELLE-DU-NET.COM',
          'MUTUELLE-DU-NET.COM CPC',
          'SanteVIES',
          'SanteVIES CPC',
          'SERENA Sante',
          'SERENA Sante CPC',
          'VIVA MUTUELLE',
          'VIVA MUTUELLE CPC'
        ]
      } else if (value == "ANIMAUX") {
        product = [
          'ASSUR ANIMAUX',
          'ASSUR ANIMAUX CPC',
          // 'ASSURER MON ANIMAL',
          // 'ASSURER MON ANIMAL CPC',
          'COSMO ASSURANCES',
          'COSMO ASSURANCES CPC',
          'DOCTANIMO',
          'DOCTANIMO CPC',
          'FELIX ASSURANCE',
          'FELIX ASSURANCE CPC',
          'IDEFIX ASSURANCE',
          'IDEFIX ASSURANCE CPC',
          'LEANIMO',
          'LEANIMO CPC',
          'MON GUSTAVE ANIMAUX',
          'MON GUSTAVE ANIMAUX LANDBOT',
          'MON GUSTAVE ANIMAUX MB',
          'MON GUSTAVE ANIMAUX MB CPC',
          'NOS ANIMAUX',
          'NOS ANIMAUX CPC',
          'ROUXYCARE',
          'ROUXYCARE CPC',
          'SantéNIMO',
          'SantéNIMO CPC',
          'SO MUTUELLE ANIMAUX',
          'SO MUTUELLE ANIMAUX CPC',
          'VETOLIA',
          'VETOLIA CPC',
          'santenimo',
          'santenimo cpc'
        ]
      } else if (value == "MRH") {
        product = [
          'ASSUR LOGEMENT',
          'ASSUR LOGEMENT CPC',
          'ASSURHOME',
          'ASSURHOME CPC',
          'HABITASSUR',
          'HABITASSUR CPC',
          'MON GUSTAVE HABITATION',
          'MON GUSTAVE MRH',
          'MON GUSTAVE MRH CPC',
          'OSIA HABITATION',
          'OSIA HABITATION CPC',
          'VESTALIA',
          'VOTRE LOGEMENT',
          'ASSUR HABITATION',
          'ASSUR HABITATION CPC',
          'VOTRE LOGEMENT CPC'
        ]
      } else if (value == "AUTO") {
        product = [
          'ASSURER MA VOITURE',
          'ASSURER MA VOITURE CPC',
          'ASSURTITINE',
          'ASSURTITINE CPC',
          'AUTODRIVE',
          'AUTODRIVE CPC',
          'CARASSUR',
          'CARASSUR CPC',
          'MON GUSTAVE AUTO',
          'MON GUSTAVE AUTO CPC',
          'MON GUSTAVE AUTO MB CPC',
          'VIAUTO CPC',
          'VIAUTO-LM',
          'VOITURANCE',
          'VOITURANCE CPC'
        ]
      } else if (value == "EMPRUNTEUR") {
        product = [
          'ASSUR EMPRUNT',
          'ASSUR EMPRUNT CPC',
          'ASSUR PRÊT',
          'ASSUR PRÊT CPC',
          'ASSURANCE DE  PRÊT',
          'ASSURANCE DE  PRÊT CPC',
          'MON GUSTAVE EMPRUNTEUR',
          'MON GUSTAVE EMPRUNTEUR CPC'
        ]
      }
      let list_id = []
      if (product != null) {
        product.forEach(element => {
          let find = this.liste_campagnes.find(x => x.Titre.toUpperCase() == element.toUpperCase());
          if (find) {
            list_id.push(find.id_programme) 
          }
        });
      }
      this.Campagne = list_id
    }
  }
};
</script>