<template>
  <div class="container-fluid">
    <ApolloQuery
      :query="require('src/graphql/CaAnnonceur.gql')"
      class="row"
      :variables="{ id_client }"
    >
      <template v-slot="{ result: { loading, error, data } }">
        <div v-if="loading" class="loading apollo">Chargement...</div>
        <div v-else-if="error" class="error apollo" style="color:red">An error occurred</div>
        <div class="col-lg-4 col-md-6">
          <stats-card
            :title="(data!=null) ? Math.floor(data.statsCaJ_1G_Lead[0].ca + data.statsCaJ_1G_Click[0].ca).toLocaleString() +' €' : ''"
            :loading='data==null'
            :sub-title="$t('Pages.dashboard.ExpensesDayBefore').toUpperCase()"
            type="default"
            icon="tim-icons icon-coins"
          >
          </stats-card>
        </div>
        <div class="col-lg-4 col-md-6">
          <stats-card
            :title="(data!=null) ? Math.floor(data.statsCaJG_Lead[0].ca + data.statsCaJG_Click[0].ca).toLocaleString() +' €' : ''"
            :loading='data==null'
            :sub-title="$t('Pages.dashboard.ExpensesDay').toUpperCase()"
            type="default"
            icon="tim-icons icon-coins"
          >
          </stats-card>
        </div>
        <div class="col-lg-4 col-md-6">
          <stats-card
            :title="(data!=null) ? Math.floor(data.statsCaMG_Lead[0].ca + data.statsCaMG_Click[0].ca).toLocaleString() +' €' : ''"
            :loading='data==null'
            :sub-title="$t('Pages.dashboard.ExpensesMonth').toUpperCase()"
            type="default"
            icon="tim-icons icon-coins"
          >
          </stats-card>
        </div>
      </template>
    </ApolloQuery>
    <div class="row">
      <div class="col-12">
        <div class="card card-chart" data="blue">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-6 text-left">
                <h2 class="card-title">{{ $t("Pages.dashboard.Statistics").toUpperCase() }}</h2>
                <h5 class="card-category" style="color:#000">
                  <template v-if="StatsWeek">
                    {{ $t("Pages.dashboard.StatsWeek").toUpperCase() }}
                  </template>
                  <template v-if="StatsMonth">
                    {{ $t("Pages.dashboard.StatsMonth").toUpperCase() }}
                  </template>
                </h5>
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-12">
                    <div class="btn-group btn-group-toggle float-right" data-toggle="buttons" v-if="StatsWeek">
                      <label
                        style="font-size: 0.75rem"
                        v-for="(option, index) in bigLineChartCategories"
                        :key="option.name"
                        class="btn btn-sm btn-default btn-simple"
                        :class="{ active: bigLineChartEditeur.activeIndex === index }"
                        :id="index"
                      >
                        <input
                          type="radio"
                          @click="initBigChartEditeur(index)"
                          name="options"
                          autocomplete="off"
                          :checked="bigLineChartEditeur.activeIndex === index"
                        />
                        <span class="d-none d-sm-block">{{ option.name }}</span>
                        <span class="d-block d-sm-none">
                          <i :class="option.icon"></i>
                        </span>
                      </label>
                    </div>
                    <div class="btn-group btn-group-toggle float-right" data-toggle="buttons" v-if="StatsMonth">
                      <label
                        style="font-size: 0.75rem"
                        v-for="(option, index) in bigLineChartMonthCategories"
                        :key="option.name"
                        class="btn btn-sm btn-default btn-simple"
                        :class="{ active: bigLineChartMonth.activeIndex === index }"
                        :id="index"
                      >
                        <input
                          type="radio"
                          @click="initBigChartMonth(index)"
                          name="options"
                          autocomplete="off"
                          :checked="bigLineChartMonth.activeIndex === index"
                        />
                        <span class="d-none d-sm-block">{{ option.name }}</span>
                        <span class="d-block d-sm-none">
                          <el-tooltip
                            :content="option.name"
                            effect="light"
                            :open-delay="300"
                            placement="top"
                          >
                            <i :class="option.icon"></i>
                          </el-tooltip>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="btn-group btn-group-toggle pull-right " data-toggle="buttons">
                      <label
                        style="font-size: 0.75rem"
                        class="btn btn-sm btn-primary btn-simple"
                        :class="{ active: StatsWeek === true }"
                        id="0">
                        <input 
                        type="radio" 
                        @click="showStatsWeek"
                        name="options">
                        <span class="d-none d-sm-block d-md-block d-lg-block d-xl-block">{{ $t("buttons.BtnWeek").toUpperCase() }}</span>
                        <span class="d-block d-sm-none">
                          <el-tooltip
                            :content="$t('buttons.BtnWeek').toUpperCase()"
                            effect="light"
                            :open-delay="300"
                            placement="top"
                          >
                            <i class="tim-icons icon-chart-pie-36"></i>
                          </el-tooltip>
                        </span>
                      </label>
                      <label 
                        style="font-size: 0.75rem"
                        class="btn btn-sm btn-primary btn-simple" 
                        :class="{ active: StatsMonth === true }"
                        id="1">
                        <input 
                        type="radio" class="d-none d-sm-none" 
                        @click="showStatsMonth"
                        name="options">
                        <span class="d-none d-sm-block d-md-block d-lg-block d-xl-block">{{ $t("buttons.BtnMonth").toUpperCase() }}</span>
                        <span class="d-block d-sm-none">
                          <el-tooltip
                            :content="$t('buttons.BtnMonth').toUpperCase()"
                            effect="light"
                            :open-delay="300"
                            placement="top"
                          >
                            <i class="tim-icons icon-chart-pie-36"></i>
                          </el-tooltip>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <base-button 
              v-if="$apollo.queries.statsGlobales.loading" 
              style="position: absolute;top: 60%;
                left: 50%;
                -moz-transform: translateX(-50%) translateY(-50%);
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);" 
              loading type="default">
            </base-button>
            <div class="chart-area">
              <line-chart
                v-if="!$apollo.queries.statsGlobales.loading && StatsWeek"
                style="height: 100%"
                ref="bigChart"
                :chart-data="bigLineChartEditeur.chartData"
                :gradient-colors="bigLineChartEditeur.gradientColors"
                :gradient-stops="bigLineChartEditeur.gradientStops"
                :extra-options="bigLineChartEditeur.extraOptions"
              >
              </line-chart>
              <line-chart
                v-show="!$apollo.queries.statsGlobales.loading && StatsMonth"
                style="height: 100%"
                ref="bigChartMonth"
                :chart-data="bigLineChartMonth.chartData"
                :gradient-colors="bigLineChartMonth.gradientColors"
                :gradient-stops="bigLineChartMonth.gradientStops"
                :extra-options="bigLineChartMonth.extraOptions"
              ></line-chart>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import StatsCard from 'src/components/Cards/StatsCard';
import LineChart from '@/components/Charts/LineChart';
import config from '@/config';
import * as chartConfigs from '@/components/Charts/config';
import gql from 'graphql-tag'

let bigChartDataEditeur = [
  [],
  [],
  []
]

let bigChartDataMonth = [
  [], 
  [], 
  []
];

let Mounth = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Aout",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre"
]

let bigChartLabelsMonth = ['LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM', 'DIM'];

let bigChartLabels = ['LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM', 'DIM']
let bigChartDatasetOptions = {
  fill: true,
  borderColor: config.colors.myAfill,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: config.colors.myAfill,
  pointBorderColor: 'rgba(255,255,255,0)',
  pointHoverBackgroundColor: config.colors.myAfill,
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4,
}

export default {
  apollo: {
    statsCaWeek_Editeur: {
      query: gql`query ($id_client: Int!){
        statsCaWeek_Lead(droit:"an",id_client:$id_client){
          day
          ca
        },
        statsCaWeek_Click(droit:"an",id_client:$id_client){
          day
          ca
        }
      }`,
      variables () {
        return {
          id_client: this.id_client,
        }
      },
      update: data => data
    },
    statLeadsOfWeekEditeur: {
      query: gql`query ($id_client: Int!){
        statLeadsOfWeek(droit:"an",id_client:$id_client){
          day
          leads
        }
      }`,
      variables () {
        return {
          id_client: this.id_client,
        }
      },
      update: data => data.statLeadsOfWeek
    },
    statShootOfWeekEditeur: {
      query: gql`query ($id_client: Int!){
        statShootOfWeek(droit:"an", id_client:$id_client){
          day
          shoots
        }
      }`,
      variables () {
        return {
          id_client: this.id_client,
        }
      },
      update: data => data.statShootOfWeek  
    },
    statShootOfMonth: {
      query: gql`
        query($id_client: Int,$month: String!, $year: String!) {
          statShootOfMonth(id_client: $id_client,month: $month,year:$year,droit: "an"){
            day
            shoots
          }
        }
      `,
      variables() {
        return {
          month: (new Date().getUTCMonth() + 1) + "",
          year: new Date().getUTCFullYear() + "",
          id_client: this.id_client
        };
      },
      result({ data, loader, networkStatus }) {
        let Shootdata = [];
        let label = [];
        for (let index = 0; index < data.statShootOfMonth.length; index++) {
          label.push(data.statShootOfMonth[index].day+"/"+ Mounth[new Date().getUTCMonth()])
          Shootdata.push(data.statShootOfMonth[index].shoots)
        }
        bigChartLabelsMonth = label;
        bigChartDataMonth[1] = Shootdata;
      }
    },
    statsGlobales: {
      query: gql`
        query($day: Int, $month: String!, $year: String!, $id_client: Int) {
          statsGlobales(
            day: $day
            month: $month
            year: $year
            droit: "an"
            id_client: $id_client
          ) {
            numero_jour
            flag
            returnDay
            jour
            leads
            ca
          }
        }
      `,
      fetchPolicy: "no-cache",
      skip() {
        return this.SkipQuery;
      },
      variables() {
        return {
          id_client: this.id_client,
          day: null,
          month: (new Date().getUTCMonth() + 1) + "",
          year: new Date().getUTCFullYear() + ""
        };
      },
      result({ data, loader, networkStatus }) {
        this.returnDay = data.statsGlobales[0].returnDay;
        this.Flag = data.statsGlobales[0].flag;
        this.AllstatsGlobales = [
          ...this.AllstatsGlobales,
          ...data.statsGlobales
        ];
      }
    }
  },
  watch: {
    statLeadsOfWeekEditeur: function(val) {
      this.statLeadsOfWeekEditeur.map(function(value, key) {
        bigChartDataEditeur[2][key] = value.leads
      })
    },
    statShootOfWeekEditeur: function(val) {
      this.statShootOfWeekEditeur.map(function(value, key) {
        bigChartDataEditeur[1][key] = value.shoots
      })
    },
    statsCaWeek_Editeur: function(val) {
      for (let i = 0; i <  this.statsCaWeek_Editeur.statsCaWeek_Lead.length; i++) {
        if(this.statsCaWeek_Editeur.statsCaWeek_Lead[i].ca == null && this.statsCaWeek_Editeur.statsCaWeek_Click[i].ca == null)
          bigChartDataEditeur[0][i] = null
        else
          bigChartDataEditeur[0][i] = (this.statsCaWeek_Editeur.statsCaWeek_Lead[i].ca + this.statsCaWeek_Editeur.statsCaWeek_Click[i].ca).toFixed(2)
      }
      bigChartLabels = this.setChartLabels()
      this.initBigChartEditeur(2)
    }
  },
  components: {
    StatsCard,
    LineChart
  },
  data(){
    return {
      AllstatsGlobales: [],
      SkipQuery: true,
      StatsWeek: true,
      StatsMonth: false,
      role: JSON.parse(localStorage.getItem('utilisateur')).law,
      statsCaWeek_Editeur: null,
      statLeadsOfWeekEditeur: null,
      id_client: JSON.parse(localStorage.getItem('utilisateur')).id_clien_myaffil,
      bigLineChartEditeur: {
        activeIndex: 2,
        chartData: {
          datasets: [{
            ...bigChartDatasetOptions,
            data: bigChartDataEditeur[0]
          }],
          labels: bigChartLabels
        },
        extraOptions: {
          ...chartConfigs.purpleChartOptions,
          tooltips: {
            callbacks: {
              label: function(tooltipItems, data) {
                if(data.datasets[0].data == bigChartDataEditeur[0])
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " €"
                else if(data.datasets[0].data == bigChartDataEditeur[1])
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " shoots"
                else
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " leads"
              }
            }
          },
          scales: {
            yAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.0)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  suggestedMax: 10,
                  suggestedMin: 0,
                  padding: 20,
                  fontColor: '#9e9e9e',
                  callback: function (value){
                    return value.toLocaleString()
                  }
                }
              }
            ],
            xAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(253,93,147,0.1)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  padding: 20,
                  fontColor: '#9e9e9e'
                }
              }
            ]
          }
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: []
      },
      bigLineChartMonth: {
        activeIndex: 2, 
        chartData: {
          datasets: [
            {
              ...bigChartDatasetOptions,
              data: bigChartDataMonth[0]
            }
          ],
          labels: bigChartLabelsMonth
        },
        extraOptions: {
          ...chartConfigs.purpleChartOptions,
          tooltips: {
            callbacks: {
              label: function(tooltipItems, data) {
                if(data.datasets[0].data == bigChartDataMonth[0])
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " €"
                else if(data.datasets[0].data == bigChartDataMonth[1])
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " shoots" 
                else
                  return Math.floor(tooltipItems.yLabel).toLocaleString() + " leads"
              }
            }
          },
          scales: {
            yAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.0)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  suggestedMax: 10,
                  suggestedMin: 0,
                  padding: 20,
                  fontColor: '#9e9e9e',
                  callback: function (value){
                    return value.toLocaleString()
                  }
                }
              }
            ],
            xAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(253,93,147,0.1)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  padding: 20,
                  fontColor: '#9e9e9e'
                }
              }
            ]
          }
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: []
      }
    }
  },
  computed: {
    bigLineChartCategories() {
      return [
        { name: 'DÉPENSES', icon: 'tim-icons icon-coins' }, 
        { name: 'SHOOTS', icon: 'tim-icons icon-spaceship' }, 
        { name: 'LEADS', icon: 'tim-icons icon-badge' }
      ];
    },
    bigLineChartMonthCategories() {
      return [
        { name: 'DÉPENSES', icon: 'tim-icons icon-coins' }, 
        { name: 'SHOOTS', icon: 'tim-icons icon-spaceship' }, 
        { name: 'LEADS', icon: 'tim-icons icon-badge' }
      ];
    }
  },
  methods: {
    setChartLabels() {
      let curr = new Date
      var yesterday = new Date(curr.getTime());
      yesterday.setDate(curr.getDate() - 1);
      let week = []
      for (let i = 1; i <= 7; i++) {
        let first = yesterday.getDate() - yesterday.getDay() + i 
        let day = new Date(yesterday.setDate(first)).toISOString().slice(0, 10).split("-")
        week.push(day[2]+"/"+ Mounth[parseInt(day[1]-1)])
      }
      return week
    },
    initBigChartEditeur(index) {
      let chartData = {
        datasets: [{
          ...bigChartDatasetOptions,
          data: bigChartDataEditeur[index]
        }],
        labels: bigChartLabels
      };
      //this.$refs.bigChart.updateGradients(chartData);
      this.bigLineChartEditeur.chartData = chartData;
      this.bigLineChartEditeur.activeIndex = index;
    },
    initBigChartMonth(index) {
      let chartData = {
        datasets: [
          {
            ...bigChartDatasetOptions,
            data: bigChartDataMonth[index]
          }
        ],
        labels: bigChartLabelsMonth
      };
      this.$refs.bigChartMonth.updateGradients(chartData);
      this.bigLineChartMonth.chartData = chartData;
      this.bigLineChartMonth.activeIndex = index;
    },
    showStatsWeek() {
      this.initBigChartEditeur(2)
      this.StatsWeek = true
      this.StatsMonth = false
    },
    showStatsMonth() {
      this.initBigChartMonth(2)
      this.StatsWeek = false
      this.StatsMonth = true
    },
    async sendGlobalMonth() {
      this.SkipQuery = false;
      while (!this.Flag) {
        await this.$apollo.queries.statsGlobales.refetch({
          day: this.returnDay,
          month: (new Date().getUTCMonth() + 1) + "",
          year: new Date().getUTCFullYear() + "",
          droit: "an",
          id_client: this.id_client
        });
      }
      
      let CAdata = [];
      let Leaddata = [];
      for (let index = 0; index < this.AllstatsGlobales.length; index++) {
        CAdata.push(
          this.AllstatsGlobales[index].ca != null
            ? this.AllstatsGlobales[index].ca.toFixed(1)
            : this.AllstatsGlobales[index].ca
        );
        Leaddata.push(this.AllstatsGlobales[index].leads);
      }
      bigChartDataMonth[0] = CAdata;
      bigChartDataMonth[2] = Leaddata;
      this.initBigChartMonth(2);
      this.SkipQuery = true;
    },
  },
  created() {
    this.sendGlobalMonth()
  }
};
</script>
<style>
  .campagnes {background: #ffffff;padding:10px 0px; margin:5px 0; border-radius: 10px; box-shadow: 0px 0px 2px #999;
      background: linear-gradient(0deg, #51357c 0%, #988fc3 65%, #ffffff 66%);border:3px solid #fff !important;
    }
  .campagnes h6 {background: #51357c; color:#fff; margin:-10px auto 10px ; -webkit-border-bottom-right-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-bottomright: 10px;
    -moz-border-radius-bottomleft: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px; width:80%; font-size: 12px; 
  }
  .campagnes h5 {margin:10px 0px; padding:5px 0; color:#54347C; font-weight: bold}
  .campagnes img {box-shadow: 0px 0px 2px #999;}
  .campagnes button {width:80%}
  .campagnes button.btn {padding:5px}
</style>
