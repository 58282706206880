<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-md-12">
        <card>
          <div class="col-md-12">
            <div class="row">
              <div class="col-lg-2 col-md-4 col-sm-6">
                <base-input>
                  <el-select
                    class="select-primary"
                    placeholder="MANAGER"
                    :disabled="$apollo.queries.listManager.loading"
                    v-model="ManagerSelects.Manager"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="option in ManagerSelects.ListManager"
                      class="select-primary"
                      :value="option.email_client"
                      :label="option.prenom_client +' '+ option.nom_client.toUpperCase()"
                      :key="option.id_client"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <!-- <div class="col-lg-2 col-md-4 col-sm-6">
                <base-input>
                  <el-select
                    class="select-primary"
                    placeholder="ANNONCEUR"
                    :disabled="$apollo.queries.listAnnonceur.loading"
                    v-model="AnnonceurSelects.Annonceur"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="option in AnnonceurSelects.ListAnnonceur"
                      class="select-primary"
                      :value="option.id_client"
                      :label="option.societe_client.toUpperCase()"
                      :key="option.id_client"
                    ></el-option>
                  </el-select>
                </base-input>
              </div> -->
              <!-- <div class="col-lg-2 col-md-4 col-sm-6">
                <base-input>
                  <el-select
                    class="select-primary"
                    placeholder="MODE DE RÉMUNÉRATION"
                    :disabled="$apollo.queries.listModeRemuneration.loading"
                    v-model="ModeRemunerationSelects.ModeRemuneration"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="option in ModeRemunerationSelects.ListModeRemuneration"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.value"
                    ></el-option>
                  </el-select>
                </base-input>
              </div> -->
              <div class="col-lg-2 col-md-4 col-sm-6">
                <base-input>
                  <el-select
                    class="select-primary"
                    placeholder="THÉMATIQUE"
                    :disabled="$apollo.queries.listCategories.loading"
                    v-model="ThematiqueSelects.Thematique"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="option in ThematiqueSelects.ListThematique"
                      class="select-primary"
                      :value="option.value"
                      :label="option.categorie.toUpperCase()"
                      :key="option.value"
                    >
                      {{ option.categorie.toUpperCase() }}<i :class="option.icon+'  pull-right pt-2'"></i>
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <!-- <div class="col-lg-2 col-md-4 col-sm-6">
                <base-input>
                  <el-select
                    class="select-primary"
                    placeholder="TYPE DE DIFFUSION"
                    v-model="TypeDiffusionSelects.TypeDiffusion"
                    clearable
                  >
                    <el-option
                      v-for="option in TypeDiffusionSelects.ListTypeDiffusion"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label.toUpperCase()"
                      :key="option.label"
                    ></el-option>
                  </el-select>
                </base-input>
              </div> -->
              <div class="col-lg-2 col-md-4 col-sm-6">
                <base-input>
                  <el-select
                    class="select-primary"
                    placeholder="PAYS"
                    v-model="PaysSelects.Pays"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="option in PaysSelects.ListPays"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label.toUpperCase()"
                      :key="option.label"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-2 col-md-4 col-sm-6">
                <base-input type="text" placeholder="MOTS CLÉS" v-model="keyword"></base-input>
              </div>
            </div>
          </div>
          <div class="text-right">
            <base-button
              v-if="$apollo.queries.listAnnonces.loading"
              loading
              class="btn btn-just-icon btn-default mt-0 d-block"
            ></base-button>
            <button v-else class="btn btn-just-icon btn-default" @click="reset()">{{ $t("buttons.BtnReset").toUpperCase() }}</button>
          </div>
        </card>
        <card v-if="!$apollo.queries.listManager.loading">
          <div>
            <div class="row">
              <div class="col-xl-1 col-6">
                <base-input>
                  <el-select
                    class="select-primary pagination-select"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-xl-11 col-6">
                <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
              </div>
            </div>
            <el-table :data="filterlistAnnonces">
              <el-table-column 
                prop="Titre" 
                label="Campagne" 
                class="table-striped" 
                min-width="270"
                sortable>
                <template scope="scope">
                  <div class="prog mr-2 float-left">
                    <img
                      v-if="scope.row.hostname != 'plf'"
                      :src="'https://www.weedoit.fr/tracking/partenaire/' + scope.row.repertoire + '/profil-S.png'"
                      @error="imageLoadError"
                      @click="Visualiser(scope.row)"
                      style="cursor: pointer;"
                    />
                    <img
                      v-else
                      :src="'https://weedoit-media.s3.eu-central-1.amazonaws.com/programme/' + scope.row.repertoire + '/profil-S.png'"
                      @error="imageLoadError"
                      @click="Visualiser(scope.row)"
                      style="cursor: pointer;"
                    />
                  </div>
                  <div class="pt-3">

                    <template v-if="scope.row.Titre.length <= 20">
                      {{ scope.row.Titre.toUpperCase() }}
                    </template>
                    <template v-else>
                      <el-tooltip
                        :content="scope.row.Titre"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                        <label>
                          {{ scope.row.Titre.slice(0,20).toUpperCase() }}...
                        </label>
                      </el-tooltip>
                    </template>

                  </div>
                </template>
              </el-table-column>
              <el-table-column 
                label="Rémunération" 
                class="table-striped"
                min-width="160">
                <template scope="scope">
                  <!-- <label
                    style="font-size:14px"
                    v-if="scope.row.campagne == 'lead'"
                  >{{ scope.row.prix_annonceur + ' €/lead' }} </label>
                  <label
                    style="font-size:14px"
                    v-else-if="scope.row.campagne == 'affichage'"
                  >{{ scope.row.prix_annonceur + ' €/clic' }}</label>
                  <label
                    style="font-size:14px"
                    v-else-if="scope.row.campagne == 'cpm'"
                  >{{ scope.row.prix_annonceur + ' €/CPM' }} </label>
                  <label style="font-size:14px" v-else>{{ scope.row.prix_annonceur +' €/CPA' }} </label> -->

                  <template
                    style="color:#000; font-size:14px"
                    v-if="scope.row.campagne.includes('lead')"
                  >{{ scope.row.prix_annonceur }} €/lead </template>
                  <template
                    style="color:#000; font-size:14px"
                    v-else-if="scope.row.campagne.includes('affichage')"
                  >{{ scope.row.prix_annonceur_cpc }} €/clic </template>
                  <template
                    style="color:#000; font-size:14px"
                    v-else-if="scope.row.campagne.includes('cpm')"
                  >{{ scope.row.prix_annonceur_cpm }} €/cpm </template>
                  <template 
                    style="color:#000; font-size:14px"
                    v-else-if="scope.row.campagne.includes('vente fixe')"
                  >
                    {{ scope.row.prix_annonceur_cpa_fixe }} €/cpa  
                  </template>
                  <template 
                    style="color:#000; font-size:14px"
                    v-else-if="scope.row.campagne.includes('sms')"
                  >
                    {{ scope.row.prix_annonceur_sms }} €/sms  
                  </template>
                  <template 
                    style="color:#000; font-size:14px"
                    v-else-if="scope.row.campagne.includes('pourcentage des ventes')"
                  >
                    {{ scope.row.prix_annonceur_cpa }} %/cpa  
                  </template>
                  <template 
                    style="color:#000; font-size:14px"
                    v-else
                  >
                    {{ scope.row.prix_annonceur_cpd }} €/cpd
                  </template>

                </template>
              </el-table-column>
              <el-table-column 
                prop="categorie" 
                label="thématique" 
                class="table-striped" 
                min-width="150"
                sortable
                show-overflow-tooltip>
                <template scope="scope">
                  <template v-if='ThematiqueSelects.ListThematique.find(c => c.value == scope.row.categorie)'>
                    <el-tooltip
                      :content="scope.row.categorie" 
                      effect="light" 
                      :open-delay="300" 
                      placement="top">
                        <i :class="ThematiqueSelects.ListThematique.find(c => c.value == scope.row.categorie).icon"></i>
                    </el-tooltip>
                  </template>
                  <template v-else>
                    {{ scope.row.categorie }}
                  </template>
                </template>
              </el-table-column>
              <el-table-column 
                prop="manager" 
                label="Manager" 
                min-width="260"
                class="table-striped" 
                sortable>
                <template scope="scope">
                    <template v-if="scope.row.image_manager == null">
                      <div class="tm_photo mr-2 float-left">
                        <img :src="'/img/'+ TmPhoto[(scope.$index < 7)? scope.$index : 2]" alt="photo" />
                      </div>
                    </template>
                    <template v-else>
                      <div class="tm_photo mr-2 float-left">
                        <img :src="'https://weedoit-media.s3.eu-central-1.amazonaws.com/'+scope.row.image_manager" alt="photo"/>
                      </div>
                    </template>
                  <div class="pt-1">{{ scope.row.manager }}</div>
                </template>
              </el-table-column>
              <el-table-column 
                prop label="Action" 
                class="table-striped" 
                min-width="250">
                <template scope="scope">
                  <el-tooltip
                    :content="$t('buttons.BtnView').toUpperCase()"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  >
                    <base-button
                      @click.native="Visualiser(scope.row)"
                      class="like btn-link"
                      type="info"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-tv-2"></i>
                    </base-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p
                class="card-category"
              >{{ $t('labels.Datatable.Showing') }} {{ from + 1 }} {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}</p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            ></base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { Progress } from "src/components";
import { Table, TableColumn } from "element-ui";
import gql from "graphql-tag";
import { BaseProgress } from "@/components";
import { BasePagination } from "src/components";
import { Thematiques } from "src/pages/Filters/Thematiques";

export default {
  apollo: {
    listManager: {
      query: gql`
        query {
          listManager(
            bloque: "non"
            etat: "valide"
            droit: ["ma", "tem"]
            orderBy: [
              { field: "prenom_client", order: ASC }
              { field: "nom_client", order: ASC }
            ]
          ) {
            id_client
            nom_client
            prenom_client
            email_client
          }
        }
      `,
      result({ data, loader, networkStatus }) {
        this.ManagerSelects.ListManager = data.listManager;
      }
    },
    listAnnonceur: {
      query: gql`
        query {
          listAnnonceur {
            id_client
            email_client
            societe_client
          }
        }
      `,
      result({ data, loader, networkStatus }) {
        this.AnnonceurSelects.ListAnnonceur = data.listAnnonceur;
      }
    },
    listModeRemuneration: {
      query: gql`
        query {
          listModeRemuneration {
            campagne
          }
        }
      `,
      result({ data, loader, networkStatus }) {}
    },
    listCategories: {
      query: gql`
        query {
          listCategories {
            name
          }
        }
      `,
      result({ data, loader, networkStatus }) {
        //this.ThematiqueSelects.ListThematique = data.listCategories;
      }
    },
    listAnnonces: {
      query: require("src/graphql/ListprogramWeedoPerf.gql"),
      fetchPolicy: 'network-only',
      variables() {
        return {
          id_client: JSON.parse(localStorage.getItem('utilisateur')).id_clien_myaffil,
          droit: "an",
          langue: "FR"
        }
      },
      update: data => data.filterAnnonces
    }
  },
  components: {
    BasePagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    BaseProgress,
  },
  data() {
    return {
      TmPhoto:["emilyz.jpg","james.jpg","jana.jpg","lora.jpg","mike.jpg","robi.jpg","tania.jpg"],
      tableData: [],
      nouveautes: null,
      listAnnonces: null,
      keyword: "",
      ManagerSelects: {
        Manager: "",
        ListManager: []
      },
      AnnonceurSelects: {
        Annonceur: "",
        ListAnnonceur: []
      },
      ModeRemunerationSelects: {
        ModeRemuneration: "",
        ListModeRemuneration: [
          { value: "affichage", label: "CPC" },
          { value: "cpm", label: "CPM" },
          { value: "Enrichissement", label: "ENRICHISSEMENT" },
          { value: "lead", label: "CPL" },
          { value: "pourcentage des ventes", label: "CPA" },
          { value: "sms", label: "SMS" },
          { value: "CPD", label: "CPD" },
          { value: "vente fixe", label: "CPA Fixe" }
        ]
      },
      PaysSelects: {
        Pays: '',
        ListPays: [
          {value: "de", label: "Allemagne"}, 
          {value: "ads", label: "Afrique du Sud"}, 
          {value: "aus", label: "Australie"}, 
          {value: "be", label: "Belgique"}, 
          {value: "br", label: "Brésil"}, 
          {value: "da", label: "Danemark"},
          {value: "uae", label: "Émirats arabes unis"}, 
          {value: "es", label: "Espagne"}, 
          {value: "us", label: "États-Unis"}, 
          {value: "fi", label: "Finlande"}, 
          {value: "fr", label: "france"}, 
          {value: "it", label: "Italie"}, 
          {value: "lux", label: "Luxembourg"},
          {value: "no", label: "Norvège"}, 
          {value: "nz", label: "Nouvelle-Zélande (New Zealand)"}, 
          {value: "nl", label: "Pays-Bas"}, 
          {value: "po", label: "Pologne"}, 
          {value: "pt", label: "Portugal"}, 
          {value: "uk", label: "Royaume-Uni (United Kingdom)"}, 
          {value: "ch", label: "Suisse"}, 
          {value: "se", label: "Suède"}
        ]
      },
      ThematiqueSelects: {
        Thematique: "",
        ListThematique: Thematiques
      },
      TypeDiffusionSelects: {
        TypeDiffusion: "",
        ListTypeDiffusion: [
          // { value: "emailing", label: "E-mailing" },
          // { value: "banniere", label: "Bannière" },
          // { value: "popunder", label: "Pop-under" },
          // { value: "siteunder", label: "Site-under" },
          // { value: "inc_clic", label: "Incentive au clic" },
          // { value: "inc_rempli", label: "Incentive au remplissage" },

          { value: "Avantages consommateurs", label: "Avantages consommateurs" },
          { value: "Content & Influence marketing", label: "Content & Influence marketing" },
          { value: "emailing", label: "Email Marketing" },
          { value: "Emulation", label: "Emulation" },
          { value: "Génération de leads", label: "Génération de leads" },
          { value: "Iframe", label: "Iframe" },
          { value: "Mailing", label: "Mailing" },
          { value: "Mobile", label: "Mobile" },
          { value: "Native", label: "Native" },
          { value: "Push notification", label: "Push notification" },
          { value: "Remarketing", label: "Remarketing" },
          { value: "Reseau sociaux", label: "Reseau sociaux" },
          // { value: "Search", label: " Reseau sociaux" },
          { value: "SMS", label: "SMS" }
        ]
      },
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 25, 100, 250],
        total: 0
      },
      total: 0
    };
  },
  methods: {
    imageLoadError(event) {
      event.target.src =
        "/img/missingprofile.jpg";
    },
    reset() {
      this.ManagerSelects.Manager = "";
      this.AnnonceurSelects.Annonceur = "";
      this.ModeRemunerationSelects.ModeRemuneration = "";
      this.ThematiqueSelects.Thematique = "";
      this.TypeDiffusionSelects.TypeDiffusion = "";
      this.keyword = "";
    },
    Visualiser(row) {
      localStorage.setItem('ProgrammeVisualiser', JSON.stringify(row))
      this.$router.push({name: "Visualiser programme My Affil Annonceur"})
    }
  },
  computed: {
    filterlistAnnonces() {
      var list = null;
      try {
        list = this.listAnnonces.filter(annonce => {
          return (
            annonce.categorie.match(this.ThematiqueSelects.Thematique) &&
            annonce.manager.match(this.ManagerSelects.Manager) &&
            (annonce.id_client + "").match(this.AnnonceurSelects.Annonceur) &&
            annonce.format.match(this.TypeDiffusionSelects.TypeDiffusion) &&
            annonce.campagne.match(
              this.ModeRemunerationSelects.ModeRemuneration
            ) &&
            annonce.langue.toUpperCase().match(this.PaysSelects.Pays.toUpperCase()) &&
            annonce.Titre.toUpperCase().match(this.keyword.toUpperCase())
          );
        });
        this.total = list.length;
        if(list != null) {
          return list.slice(this.from, this.to);
        }
        else
          return null
      } catch (error) {
        return null;
      }
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    }
  },
  watch: {
    filterlistAnnonces(value) {
      //console.log(this.filterlistAnnonces.length)
    }
  }
};
</script>

<style scoped>
.btn {
  white-space: nowrap;
}
.prog {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}
.prog img {
  width: 100%;
}
.tm_photo {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}
.tm_photo img {
  width: 100%;
}
.phototm {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  vertical-align: middle;
  box-shadow: 0px 0px 5px #000;
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
}

.campagnes {
  background: #ffffff;
  padding: 10px 0px;
  margin: 5px 0;
  border-radius: 10px;
  box-shadow: 0px 0px 2px #999;
  background: linear-gradient(0deg, #51357c 0%, #988fc3 65%, #ffffff 66%);
  border: 3px solid #fff !important;
}
.campagnes h6 {
  background: #51357c;
  color: #fff;
  margin: -10px auto 10px;
  -webkit-border-bottom-right-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-bottomright: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 80%;
  font-size: 12px;
}
.campagnes h5 {
  background: #fff;
  margin: 10px 0px;
  padding: 5px 0;
  color: #54347c;
  font-weight: bold;
}
.campagnes img {
  box-shadow: 0px 0px 2px #999;
}
.campagnes button {
  width: 80%;
}
.campagnes button.btn {
  padding: 5px;
}

.card .card-header h5.card-category {
  color: #fff;
}
.white-content .card .card-header h5.card-category {
  color: #1d253b;
}
.white-content .btn-neutral.btn-simple {
  color: #000;
  border-color: #000;
  background: transparent;
}

/** Card **/
.card-custom {
  overflow: hidden;
  min-height: 300px;
  box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
  width: 200px;
  margin: 0 auto;
}
.card-custom .card-body {
  margin-top: -75px;
  text-align: right;
  padding: 15px 15px 0;
  z-index: 999999;
}
.card-custom .card-body p {
  text-align: left;
}
.card-custom-img {
  height: 200px;
  min-height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-color: inherit;
}
.card-custom-img h6 {
  text-transform: none;
}
.card-custom .card-body .card-title {
  color: #fff;
  font-weight: bold;
  font-size: 12px;
}
/*#program .card-custom .card-body .card-title {min-height:60px}*/
.card-custom .card-body .card-text {
  color: #000;
}
/* First border-left-width setting is a fallback */
#program .card-footer {
  margin-top: 0;
}
.card-custom-img::after {
  position: absolute;
  content: "";
  top: 161px;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-top-width: 40px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-left-width: 545px;
  border-left-width: calc(575px - 5vw);
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: inherit;
}

.card-custom-avatar img {
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
  position: absolute;
  z-index: 99999999;
  top: 120px;
  left: 1.25rem;
  width: 35px;
  height: 35px;
}
</style>