<template>
  <card>
    <center>
      <base-button
        v-if="$apollo.queries.ListeShoots.loading"
        loading
      ></base-button>
    </center>
    <div class="card-body" v-if="!$apollo.queries.ListeShoots.loading">
      <full-calendar
        id="fullCalendar"
        v-bind="calendarOptions"
        :events="events"
        contentHeight="auto"
        ref="fullCalendar"
      />
    </div>
  </card>
</template>

<script>
  import FullCalendar from '@fullcalendar/vue';
  import dayGridPlugin from '@fullcalendar/daygrid';
  import timeGridPlugin from '@fullcalendar/timegrid';
  import interactionPlugin from '@fullcalendar/interaction';
  import gql from 'graphql-tag'

  let today = new Date();

  export default {
    components: {
      FullCalendar
    },
    apollo: {
      ListeShoots: {
        query : gql`
          query ($id_client: Int) {
            GetShoots_By_Annonceur (id_client: $id_client) {
              Titre
              volume
              jour
              mois
              annee
            }
          }
        `,
        fetchPolicy: 'network-only',
        variables() {
          return {
            id_client: JSON.parse(localStorage.getItem('utilisateur')).id_clien_myaffil
          }
        },
        update: data => data.GetShoots_By_Annonceur,
        result({ data, loader, networkStatus }) {
          this.events = []
          for (let i = 0; i < data.GetShoots_By_Annonceur.length; i++) {
            let event = {
              title: data.GetShoots_By_Annonceur[i].Titre.toUpperCase() + ' (' + data.GetShoots_By_Annonceur[i].volume.toLocaleString() +' @)',
              start: data.GetShoots_By_Annonceur[i].annee+"-"+data.GetShoots_By_Annonceur[i].mois+"-"+data.GetShoots_By_Annonceur[i].jour,
              className: 'event-green'
            }
            this.events.push(event)
          }
        }
      }
    },
    data() {
      return {
        events: [],
        calendarOptions: {
          locale: 'fr',
          plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
          theme: true,
          defaultView: 'dayGridMonth',
          header: {
            left: 'title',
            center: 'dayGridMonth,timeGridWeek,timeGridDay',
            right: 'prev,next',
          },
          buttonText: {
            month: 'mois',
            week: 'semaine',
            day: 'jour',
          },
          defaultDate: today,
          selectable: false,
          selectHelper: false,
          views: {
            month: {
              // name of view
              titleFormat: {
                month: 'long',
                year: 'numeric'
              }
              // other view-specific options here
            },
            week: {
              titleFormat: {
                month: 'long',
                day: '2-digit',
                year: 'numeric'
              }
            },
            day: {
              titleFormat: {
                day: '2-digit',
                month: 'long',
                year: 'numeric'
              }
            }
          },
          editable: false,
          eventLimit: false, // allow "more" link when too many events

          // color classes: [ event-blue | event-azure | event-green | event-orange | event-red ]
          events: self.events

        },
      }
    },
  }
</script>