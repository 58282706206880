<template>
  <div>
    <card>
      <h3>TAUX DE TRANSFORMATION DES VISUELS (MÀJ QUOTIDIENNE)</h3>
      <el-table :data="StatsVisuel" show-summary :summary-method="getSummaries">
        <el-table-column
          label="KIT"
          prop="name"
          min-width="100"
        >
          <template scope="scope">
            {{ scope.row.name.toUpperCase() }}
          </template>
        </el-table-column>
        <el-table-column
          label="impression"
          prop="impression"
          sortable
          min-width="150"
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.impression, true) }}
          </template>
        </el-table-column>
        <el-table-column
          label="CLICS"
          prop="clics"
          sortable
          min-width="150"
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.clics, true) }}
          </template>
        </el-table-column>
        <el-table-column
          label="TAUX CLIC"
          prop="taux_clic"
          sortable
          min-width="150"
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.taux_clic) + " %" }}
          </template>
        </el-table-column>
        <el-table-column
          label="LEADS"
          prop="leads"
          sortable
          min-width="150"
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.leads, true) }}
          </template>
        </el-table-column>
        <el-table-column
          label="TRANSFO"
          prop="tansformation"
          sortable
          min-width="150"
        >
          <template scope="scope">
            {{ scope.row.tansformation + " %" }}
          </template>
        </el-table-column>
      </el-table>
      <center>
        <base-button v-if='$apollo.queries.stat_visuel.loading' loading type="primary"></base-button>
      </center>
    </card>
    <card>
      <h3 class="card-title">HTML MAILING</h3>
      <div class="row">
        <div class="col-lg-2 col-md-6" v-for="(visuel, id) in listHtml" :key="visuel.id">
          <card>
            <h4 class="card-title">{{ "VISUEL " + (id+1) }}</h4>
            <div class="prog">
              <img v-if="visuel.hostname == ''" style="max-width: 155; max-height: 194; cursor: pointer;"
                :src="'https://www.weedoit.fr/tracking/partenaire/'+ visuel.repertoire + '/' +visuel.profil" @click="download('/apercu_template.html?idf='+ visuel.id_visuel + '&id_visuel=' + visuel.id_visuel + '&campagne=visuel_' + visuel.id_visuel + '&hostname=old')"/>
              <img v-if="visuel.hostname == 'plf'" style="width: 155; height: 194; cursor: pointer;"
                :src="'https://weedoit-media.s3.eu-central-1.amazonaws.com/programme/'+ visuel.repertoire.replace('é','e') + '/' +visuel.profil" @click="download('',visuel)"/>
            </div>
          </card>
        </div>
      </div>
    </card>

    <card>
      <h3 class="card-title">BANNIÈRES STANDARDS</h3>
      <center>
        <div class="col-lg-3 col-md-6">
          <card
            v-for="visuel in listBannier" :key="visuel.id"
          >
            <h4 class="card-title">{{ visuel.repertoire+": " + visuel.taille + " image - gif" }}</h4>
            <img v-if="visuel.hostname == ''" :src="'https://www.weedoit.fr/tracking/partenaire/'+ visuel.repertoire + '/' +visuel.profil" />
            <img v-if="visuel.hostname == 'plf'" :src="'https://weedoit-media.s3.eu-central-1.amazonaws.com/programme/'+ visuel.repertoire.replace('é','e') + '/' +visuel.profil" />
          </card>
        </div>
      </center>
    </card>
  </div>
</template>

<script>
import { Table, TableColumn } from 'element-ui';
import gql from "graphql-tag";

export default {
  apollo: {
    stat_visuel: {
       query: gql`query ($id_programme: Int!){
        stat_visuel (id_programme: $id_programme) {
          name
          impression
          clics
          leads
          taux_clic
          tansformation
        }
      }`,
      skip() {
        return this.SkipQuery2
      },
      variables() {
        return {
          id_programme: this.PrgVisualiser.id_programme
        }
      },
      result({ data, loader, networkStatus }) {
        this.StatsVisuel = data.stat_visuel
        let SUMLimp = 0
        let SUMclics = 0
        let SUMlead = 0
        let SUMtauxclick = 0
        let SUMtrans = 0
        this.StatsVisuel.forEach(stats => {
          SUMLimp += stats.impression
          SUMclics += stats.clics
          SUMlead += stats.leads
          SUMtauxclick += stats.taux_clic
          SUMtrans += stats.tansformation
        });
        this.SUMLimp = SUMLimp
        this.SUMclics = SUMclics
        this.SUMlead = SUMlead
        this.SUMtauxclick = (SUMLimp == 0) ? 0 : (SUMclics / SUMLimp) * 100
        this.SUMtrans = (SUMclics == 0) ? 0 : (SUMlead / SUMclics) * 100

        this.SkipQuery2 = true
      }
    },
    list_visuel: {
      query: gql`query ($id_programme: Int!){
        list_visuel (orderBy: [{ field: "id_visuel", order: DESC }],id_programme: $id_programme, etat: "valide"){
          id_visuel
          taille
          format
          profil
          repertoire
          hostname
          url
          tmp
        }
      }`,
      skip() {
        return this.SkipQuery
      },
      variables() {
        return {
          id_programme: this.PrgVisualiser.id_programme
        }
      },
      result({ data, loader, networkStatus }) {
        this.listHtml = data.list_visuel.filter(visuel => {
          return visuel.format.match("html")
        });
        this.listBannier = data.list_visuel.filter(visuel => {
          return visuel.format.match("gif")
        });
        this.SkipQuery = true
        this.SkipQuery2 = false
      }
    }
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      PrgVisualiser: null,
      SkipQuery: true,
      SkipQuery2: true,
      listHtml: null,
      listBannier: null,
      StatsVisuel: null,
      SUMLimp:0,
      SUMclics:0,
      SUMlead:0,
      SUMtauxclick:0,
      SUMtrans:0
    }
  },
  beforeMount() {
    if ((this.PrgVisualiser = JSON.parse(localStorage.getItem('ProgrammeVisualiser'))) == null) {
      this.$router.push({name: "liste programmes My Affil Annonceur"})
    } else {
      this.SkipQuery = false
      console.log(this.PrgVisualiser.id_programme)
      localStorage.removeItem("ProgrammeVisualiser")
    }
  },
  methods: {
    formatThounsends: function(value, fixe = false, number = 0){
      value != null ? value = value : value = 0
      if(fixe){
        value = value.toFixed(number)
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '';
          return;
        }
        else if( index === 1) {
          sums[index] = this.formatThounsends(this.SUMLimp);
          return;
        }
        else if( index === 2) {
          sums[index] = this.formatThounsends(this.SUMclics);
          return;
        }
        else if( index === 3) {
          sums[index] = this.formatThounsends(this.SUMtauxclick, true, 2) + " %";
          return;
        }
        else if( index === 4) {
          sums[index] = this.formatThounsends(this.SUMlead);
          return;
        }
        else if( index === 5) {
          sums[index] = this.formatThounsends(this.SUMtrans, true, 2) + " %";
          return;
        }
        else {
          sums[index] = '';
          return;
        }
      });
      return sums;
    },
    download(url, row) {
      if(row) {          
        window.open('/apercu_template.html?idf='+ row.id_visuel +'&id_visuel=' + row.url.split('/')[3] + '&campagne=' + row.url.split('/')[2] + '&hostname=' + row.hostname);
      }
      else
        window.open(url);
    },
  }
}
</script>

<style scoped>
  .prog {
    height:auto;
    width: 140px;
    border-radius:none;
    overflow: hidden;
    margin: 0 auto;
  }
  .prog img {
    width: 100%;
  }
  </style>